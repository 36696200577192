import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";


// On récupère la langue depuis l'URL
const path = window.location.pathname;
const lang = path.startsWith("/en") ? "en" : "fr";

i18n.changeLanguage(lang);

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
