import React from 'react';
import { NavLink } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import CountryFlag from 'react-country-flag';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { i18n } = useTranslation();

    return (
        <header className="header-container">
            <div className="logo">IBOU</div>
            <nav>
                <ul className="nav-links">
                    <li>
                        <NavLink exact to="/" activeClassName="active">
                            {i18n.t('header.home')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" activeClassName="active">
                            {i18n.t('header.about')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/services" activeClassName="active">
                            {i18n.t('header.services')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/blog" activeClassName="active">
                            {i18n.t('header.blog')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" activeClassName="active">
                            {i18n.t('header.contact')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/support" activeClassName="active">
                            {i18n.t('header.support')}
                        </NavLink>
                    </li>
                </ul>
            </nav>

            <div className="language-dropdown">
                <DropdownButton
                    title={<CountryFlag countryCode={i18n.language === 'en' ? 'GB' : 'FR'} svg />}
                    variant="outline-secondary"
                    id="language-dropdown"
                    className="language-dropdown-button"
                    alignRight
                >
                    <Dropdown.Item
                        as="button"
                        onClick={() => i18n.changeLanguage('fr')}
                        active={i18n.language === 'fr'}
                    >
                        <CountryFlag countryCode="FR" svg /> Français
                    </Dropdown.Item>
                    <Dropdown.Item
                        as="button"
                        onClick={() => i18n.changeLanguage('en')}
                        active={i18n.language === 'en'}
                    >
                        <CountryFlag countryCode="GB" svg /> English
                    </Dropdown.Item>
                </DropdownButton>
            </div>
        </header>
    );
};

export default Header;