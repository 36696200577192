import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <div
            style={{ overflow: 'hidden' }} // masquer la barre de défilement
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <h1 style={{ fontSize: '5rem' }}>{t('404')}</h1>
            </div>
        </div>
    );
};

export default NotFound;
